import './App.css';
import "./bootstrap.min.css"
import Home from './screens/Home';
import { Routes, Route, Navigate } from 'react-router-dom';

import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";

import NotFound from "./screens/NotFound"


import LocationScreen from './screens/LocationScreens/LocationScreen';
import LocationPages from "./component/LocationPages_/LocationPages"

import ThankYou from './screens/ThankYou';
import About from './screens/About';
import Blog from './screens/Blog';
import Contact from './screens/Contact';
import WholesaleOffers from './screens/WholesaleOffers';
import BlogInner from './screens/BlogInner';
import PrivacyPolicy from './screens/PrivacyPolicy';


function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Navigate to="/" replace={true}/>} />
        
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogInner />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/wholesale-offers" element={<WholesaleOffers />} />

        <Route path="/admin" element={<AdminScreen />} />
        <Route path="/admin/blogs" element={<BlogsScreen />} />
        <Route path="/login" element={<LoginScreen />} />

        
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="*" element={<NotFound />} />

        
        <Route path='/location/:slug' element={<LocationPages />} />
        <Route path='/admin/location-pages' element={<LocationScreen />}/>
      </Routes>
    </div>
  );
}

export default App;


