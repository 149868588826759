
function TestimonialCard(props) {
 
  return (
    <div className="testimonial-card">
      
      <div className="d-flex flex-column px-xl-4 px-2">

        {/* <img src="/images/icons/Saying-quotes.svg" className="mx-auto" style={{width:"35px"}} alt='icon'/> */}
        <div className="testimonial-card-info pb-2">
          <div className="profile-info px-2 w-100" >
            {
              props.isSold &&
              <div className="sold-container">
                  SOLD
              </div>
            }
           {
              props.isVideo ? (
                <video
                  src={props.thumbnail}
                  className="w-100 img-fluid mb-3"
                  autoPlay
                  loop
                  muted
                  playsInline
                  webkit-playsinline="true"
                />
              ) : (
                <img src={props.thumbnail} alt="thumbnail icon" className="w-100 img-fluid mb-3" />
              )
            }
            <div className="d-flex align-items-center justify-content-between w-100 mt-4 mb-2">
              <h5 className="sub-heading mb-0" style={{color:"#232323"}}>{props.title}</h5>
              <img src="/images/icons/arrow-bordered-icon.svg" alt="arrow "/>
            </div>

            <div className="text-line-details text-start w-100">
              <p className="body-paragraph mb-1">{props.desc}</p>
              <p className="body-paragraph mb-1">{props.spec}</p>
              <p className="body-paragraph mb-1">{props.ourPrice}</p>
              <p className="body-paragraph mb-1">{props.rPrice}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialCard