import React from 'react'
import "../PrivacyPolicy.css"
import {Txt} from "./PrivacyPolicyText"
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'

function PrivacyPolicy() {
  return (
    <>
         <HeroBanner
        subHeading=""
        Heading="Privacy Policy"
        Paragraph=""
        NextLinePara=""
        bgImg="/images/creatives/plane-hero-bg.png"
        btn={false}
        sideLinks={false}
      />
        <div className='privacy-policy-container container'>
            <div dangerouslySetInnerHTML={{__html:Txt}}></div>
            
        </div>
        <Footer
        newsletterSection={false}
      />
    </>
  )
}

export default PrivacyPolicy