import React from 'react'
import "./GoogleRatingSection.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,Pagination  } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';

const testimonialData =[
  {
    clientName: "Dean Kulla",
    clientReview: "Lee was amazing to work with. I was able to have a couple rings fixed and diamond earrings for my daughters. I highly recommend Lee for any diamonds.",
    
  },
  {
    clientName: "Tanya P",
    clientReview: "I couldn't be happier with the stunning result of my new ring. Highly recommend Lee David Diamonds for anyone looking for a perfect, custom engagement ring!",
    
  },
  {
    clientName: "J.D Moore",
    clientReview: "Lee was great through the entire Christmas gift shopping process and made it fun, stress-free and informative. He asks lots of questions and gives great recommendations to help you find that perfect piece - thanks Lee!",
    
  },
  {
    clientName: "Tomas dela Cruz",
    clientReview: "Lee is a great person to deal with. He is very knowledgeable and listens to what your specific need and works through it. LDD has great products too.",
    
  },
  {
    clientName: "Altaf Virani",
    clientReview: "I had an amazing experience shopping for a very specific type of jewelry within a budget. Lee walked me through the entire process showing multiple options. Once the prototype was picked, he and his team got to work. Thanks Lee!",
    
  },
  {
    clientName: "Leon Becker",
    clientReview: "There was so much support. Being a bit of a shy person, I really appreciate the great patience and expertise shown. I highly recommend you explore his services and pricing before deciding. It will definitely save you a ton of money.",
    
  },

];


function GoogleRatingSection() {
  return (
    <div className='GoogleRatingSection-container standard-margin-space pt-5'>
        <div className='container py-5 px-5'>
          <h3 className='body-heading text-center mb-5 mt-4'>What Our Client Say ABout US</h3>
            <div className='d-flex flex-lg-row flex-column gap-5 align-items-center justify-content-between'>
                <img src='/images/icons/google-review.png' alt='google review' className='google-review-icon'/>

                <button className='genral-btn-light' onClick={()=>{window.open("https://g.co/kgs/J2sdmST")}}>see more</button>
            </div>
            <div className='testimonial-list'>
            <Swiper
                spaceBetween={20}
                navigation={{
                nextEl: '.swiper-button-next-test',
                prevEl: '.swiper-button-prev-test',
                }}
                loop={true}
                autoplay={
                {
                    delay:4500,
                    disableOnInteraction:false
                }
                }
                // modules={[Autoplay,Pagination]}
                modules={[Autoplay]}
                pagination={{ clickable: true }}
                observer ={true}
                observeParents={true}
                parallax={true} 
                
                breakpoints={{
                    280: {
                    slidesPerView: 1,
                    },
                    993: {
                    slidesPerView: 3,
                    }
                }}
                className="testimonials-list py-5 mt-2"
                style={{position:"relative"}}
                id="test-cards-slider"
                    >
                   
                        {
                        testimonialData.map((clientData,ind)=>{
                        return(<>
                            <SwiperSlide key={ind} className="pt-5 px-3 px-sm-0">
                            <TestimonialCard clientImg={clientData.clientImg} clientName={clientData.clientName} clientReview={clientData.clientReview}/>
                            </SwiperSlide>
                            
                            </>)
                        })
                    }
                   
                </Swiper>
            </div>
        </div>
    </div>
  )
}

function TestimonialCard(props)
{
  const pastelColors = [
    "#FFB6C1", // Light Pink
    "#FFD700", // Gold
    "#90EE90", // Light Green
    "#87CEFA", // Light Sky Blue
    "#FF69B4", // Hot Pink
    "#FFA07A", // Light Salmon
    "#8A2BE2", // Blue Violet
    "#20B2AA", // Light Sea Green
    "#FF6347", // Tomato
    "#6495ED"  // Cornflower Blue
  ];
  
  function getRandomPastelColor() {
    return pastelColors[Math.floor(Math.random() * pastelColors.length)];
  }
    const paragraphs = props.clientReview.split('\n').map((paragraph, index) => <p key={index} className={`review-text color-dark px-2`}>{paragraph}</p>);
 
    return(
        <div className="testimonial-card">
            <div className='d-flex align-items-center gap-3'>
                {
                  props.clientImg ?
                    <img src={props.clientImg} alt="user" className="user-img" />
                    :
                    <div 
                      className='img-placeholder d-flex align-items-center justify-content-center'
                      style={{backgroundColor:`${getRandomPastelColor()}`}}
                    >
                      <h3 className='mb-0' >{props.clientName[0]}</h3>
                      </div>
                }
                <div className='d-flex flex-column'>
                    <h6 className='clientName-text'>{props.clientName}</h6>
                    <img src='/images/icons/star-icon.png' alt='star group' className='star-icons' />
                </div>
            </div>
       <div className="testimonial-card-info pb-2">
            <div className="profile-info px-2 w-100">
                <p className="review-text body-paragraph review-text-main mt-2 color-dark ">{paragraphs}</p>
            </div>
        </div>
    </div>
    )
}

export default GoogleRatingSection