import { useState } from "react";
import "./Footer.css"
import { Link,useNavigate } from "react-router-dom";
import axios from "axios"
import {Spinner } from "react-bootstrap"


function Footer({
    newsletterSection=true
}) {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
  
    const formSubmitHandler = (e) => {
      e.preventDefault();
      setLoading(true);
      axios
        .post("/send-newsletter", {
          email: email,
          formType: "Newsletter",
        })
        .then((response) => {
          if (response.data.msg === "success") {
            setError(false);
            setSuccess("Newsletter Subscribed Successfully!");
            setLoading(false);
            setEmail("");
          } else if (response.data.msg === "fail") {
            setSuccess(false);
            setError("Email failed to send, try again by reloading the page.");
            setLoading(false);
            setEmail("");
          }
          })
        }
  return (
    <>
    {newsletterSection && 
    <div className="news-letter-container mb-5 pb-5">
            <div className="container py-5 px-4">
                <div className="form-container row gy-5 py-5 my-4">
                    <div className="col-12 mt-0">
                        <div 
                            className="text-container text-center d-flex flex-column align-items-center gap-3"

                        >
                           <p className="body-paragraph" style={{color:"white"}}>Be the first to know our Lee David Diamonds news</p>
                            <h3 className="body-heading text-center main-heading" style={{textTransform:"uppercase",color:"white"}}>
                            JOIN US NOW
                            </h3>
                        </div>
                    </div>
                    <div className="col-lg-8 d-flex align-items-center justify-content-center mx-auto">
                        <form className='inp-container d-flex align-items-center justify-content-between' onSubmit={formSubmitHandler}>
                            <input 
                                type='text' 
                                placeholder='Enter your Email...'  
                                value={email}
                                maxLength="36"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button className='inp-btn d-flex align-items-center gap-2 '>
                                <span>SUBSCRIBE</span>
                                <img src="/images/icons/btn-bell.svg" className="bell-icon mb-0" alt="icon" loading='lazy'/>
                                {loading ? (
                                    <Spinner
                                    animation="border"
                                    role="status"
                                    style={{ color: "black", width: "15px", height: "15px", marginLeft: "10px" }}
                                    />
                                ) : (
                                    success ? (
                                    <div >
                                        {setLoading(false)}
                                        {alert(success)}
                                        {setSuccess(false)}
                                    </div>
                                    ) : (
                                    error && (
                                        <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                        </div>
                                    )
                                    )
                                )}
                            </button>
                        </form>
                    </div>
                
                
                </div>
            </div>
            </div>
        }
    
    <div className={`footer-container `}>
        <div className="container px-0">
        <div className="form container">
        
        </div>
        {/* newsletter Upper section */}
        <div className="d-flex align-items-center pt-5">
        <div className="line-seprator"></div>
        <img  src="/images/icons/footer-nav-logo.png" alt="brand icon" className="img-fluid brand-logo" onClick={()=>{navigate("/")}} style={{cursor:"pointer"}} loading='lazy'/>
        <div className="line-seprator"></div>

        </div>
        <div className="upper-newsletter-container container-fluid mx-auto mt-5 pt-4" >

            <div className="row d-flex align-items-start gy-5">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="text-container pe-lg-5 pe-0">
                        {/* <div className="newsletter-logo img-fluid mb-3">
                            <img  src="/images/icons/footer-nav-logo.png" alt="brand icon" className="img-fluid" onClick={()=>{navigate("/")}} style={{cursor:"pointer"}} loading='lazy'/>
                        </div> */}
                        <div className="text-content">
                            <p className="body-paragraph my-3" style={{color:"white"}}>Connect with us on social media</p>
                        </div>
                        <div className="social-link d-flex align-items-center gap-3 mt-2">
                           
                            <a href="https://www.instagram.com/leedaviddiamonds/" target="blank" rel="noopener noreferrer">
                                <img src="/images/icons/insta-icon.svg" alt="icon" className="social-icons" loading='lazy'/>
                            </a>
                            <a href="https://www.facebook.com/LeeDavidDiamonds/" target="blank" rel="noopener noreferrer">
                                <img src="/images/icons/fb-icon.svg" alt="icon" className="social-icons" loading='lazy'/>
                            </a>
                           
                            



                        </div>
                        
                    
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 col-12">
                    <div className="page-links d-flex flex-md-row gap-3 flex-column align-items-md-start align-items-center text-center justify-content-md-evenly justify-content-center">
                        <div className="text-md-start text-center d-flex flex-column gap-3">
                            {/* <h3 className="footer-title color-light font-mons">Quick Links</h3> */}
                            <Link to={"/"} className="anchor" exact activeClassName="active">
                                HOME
                            </Link>
                    
                            <Link to={"/about"} className="anchor" exact activeClassName="active">
                            ABOUT
                            </Link>
                            
                            <Link to={"/blog"} className="anchor" exact activeClassName="active">
                            BLOGS
                            </Link> 
                            <Link to={"/wholesale-offers"} className="anchor" exact activeClassName="active">
                            WHOLESALE OFFERS
                            </Link> 
                            <Link to={"/contact"} className="anchor" exact activeClassName="active">
                            CONTACT US  
                            </Link> 
                           
                        </div>
                        <div className="text-md-start text-center d-flex flex-column  gap-3">
                            {/* <h3 className="footer-title color-light font-mons mt-4 mt-lg-0">Consultation</h3> */}
                            <a href="https://scheduler.zoom.us/lee-david/consultation" target="blank" className="anchor" rel="noreferrer">
                            BOOK AN APPOINTMENT 
                            </a> 
                            
                            <Link to={"/privacy-policy"} className="anchor" exact activeClassName="active">
                            PRIVACY POLICY
                            </Link> 
                            
                            
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-12">
                    <div className="info-container">
                    {/* <h3 className="footer-title color-light font-mons mb-5 text-center text-lg-start">Get in Touch</h3> */}
                       <div className="d-flex flex-column align-items-center justify-content-start gap-4 ">
                            <div className="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 w-100">
                                <img src="/images/icons/phone-location-icon.svg" alt="icon" loading='lazy'/>
                                <div>
                                    <a href="tel:(905) 467-7766" className="body-heading info-content" style={{color:"white",textDecoration:"none",cursor:"pointer"}}>(905) 467-7766</a>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-lg-start justify-content-center gap-2  w-100">
                                <img src="/images/icons/mail-location-icon.svg" alt="icon" loading='lazy'/>
                                <div>
                                <a href="mailto:LeeDavidDiamonds@gmail.com" className="body-heading info-content" style={{color:"white",textDecoration:"none",textTransform:"none",cursor:"pointer"}}>LeeDavidDiamonds@gmail.com</a>
                                    
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 w-100">
                                <img src="/images/icons/footer-location-icon.svg" alt="icon" loading='lazy'/>
                                <div>
                                    
                                    <p className="body-heading info-content mb-0" style={{textTransform:"capitalize"}}>Serving Milton and the GTA</p>
                                </div>
                            </div>
                           
                       </div>
                    </div>
                </div>

            </div>
            
           
        </div>
        </div>
        {/* div for border lining */}
        <div className="my-5"></div>

        {/* Lower sections */}

        <div className="lower-section text-center py-2">
            <div className="lower-container container flex-column gap-1 flex-md-row justify-content-md-between justify-content-center">
                <p className="mb-0">Made With ❤️ By <a href="https://www.creativesquad.ca" target="blank">Creative Squad </a></p>
            <p className="mb-0">© 2024 | Lee David Diamonds | All rights reserved</p>
            
            </div>
        </div>
    </div>
    </>
  )
}

export default Footer