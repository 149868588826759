import React from 'react'
import "./ContactPageContactDetails.css"

function ContactPageContactDetails() {
  return (
    <div className='ContactPageContactDetails-container p-4 pb-5 mb-5'>
        <div className='container'>
        <div className='card-section row'>
                <div className='card-box col-lg-6 d-flex flex-column align-items-center  px-5 py-5'>
                    <img src='/images/icons/contactPageContactDetails-icon-3.png' alt='icon' className='my-5'/>
                    <h3 className='body-heading mb-2 text-center text-lg-start' style={{color:"#232323",fontSize:"28px"}}>CONTACT EXPERT</h3>
                    <p className='body-paragraph text-center mb-4' style={{color:"#7F837A"}}>(905) 467-7766</p>
                    <a href='tel:(905) 467-7766' className='mt-3' style={{color:"#232323",textDecoration:"underline",fontWeight:"bold",fontSize:"15px"}}>CONTACT US</a>
                </div>
                <div className='card-box col-lg-6 d-flex flex-column align-items-center  px-5 py-5'>
                    <img src='/images/icons/contactPageContactDetails-icon-1.png' alt='icon' className='my-5'/>
                    <h3 className='body-heading mb-2 text-center text-lg-start' style={{color:"#232323",fontSize:"28px"}}>CONTACT EMAIL</h3>
                    <p className='body-paragraph text-center mb-4' style={{color:"#7F837A"}}>LeeDavidDiamonds@gmail.com</p>
                    <a href='mailto:LeeDavidDiamonds@gmail.com' className='mt-3' style={{color:"#232323",textDecoration:"underline",fontWeight:"bold",fontSize:"15px"}}>SEND EMAIL</a>
                </div>
                {/* <div className='card-box col-lg-4 d-flex flex-column align-items-center  px-5 py-5'>
                    <img src='/images/icons/contactPageContactDetails-icon-2.png' alt='icon' className='my-5'/>
                    <h3 className='body-heading mb-2 text-center text-lg-start' style={{color:"#232323",fontSize:"28px"}}>PICKUP LOCATION</h3>
                    <p className='body-paragraph text-center mb-4' style={{color:"#7F837A"}}>Serving Milton and the GTA</p>
                    <a href='#' className='mt-3' style={{color:"#232323",textDecoration:"underline",fontWeight:"bold",fontSize:"15px"}}>SEE LOCATION</a>
                </div> */}
                

            </div>
        </div>
    </div>
  )
}

export default ContactPageContactDetails