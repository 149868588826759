import React from 'react'
import "./PlainCenterText.css"

function PlainCenterText({
    bgImg="",
    subHeading="",
    heading="",
    customCodeHeading,
    paragraph="",
    fontDark=false,
    cardSection=false,
    shrinkedHeading=true
}) {
  return (
    <div className='PlainCenterText-container standard-padding-space'
        style={{
            backgroundImage:`url("${bgImg}")`
        }}
    >
        <div className='container py-5 text-center my-5'>
            <h5 className='sub-heading my-4' style={{color:`${fontDark ? "black":"#EBE3D0"}`}}>{subHeading}</h5>
            {heading && <h3 className={`body-heading mb-3 ${shrinkedHeading ? shrinkedHeading :"shrinked-heading"}`} style={{color:`${fontDark ? "#232323":"white"}`}}>{heading}</h3>}
            { customCodeHeading && <h3 ><div className={`body-heading mb-3 ${shrinkedHeading ? shrinkedHeading :"shrinked-heading"}`} style={{color:`${fontDark ? "#232323":"white"}`}} dangerouslySetInnerHTML={{__html:customCodeHeading}}></div></h3>}
            <p className='body-paragraph' style={{color:`${fontDark ? "#818181":"white"}`}}>{paragraph}</p>

            {
                cardSection && 

                <div className='row'>
                    {
                        cardSection.map((data)=>{
                            return(
                                <div className='card-box col-lg-4 d-flex flex-column align-items-center  px-5 py-4'>
                                    <img src={data.img} alt='icon' className='mt-5 mb-3'/>
                                    {/* <h3 className='body-heading mb-2' style={{color:"#232323",fontSize:"28px"}}>{data.title}</h3> */}
                                    <h3 className='body-heading mb-2' style={{color:"#232323",fontSize:"28px"}} dangerouslySetInnerHTML={{__html:data.title}}></h3>
                                    <p className='body-paragraph text-center mt-3' style={{color:"#818181"}}>{data.content}</p>
                                </div>
                            )
                        })
                    }
                    
                </div>
            }

        </div>

    </div>
  )
}

export default PlainCenterText