import React,{useState,useEffect} from 'react'
import BlogInnerHeroBanner from '../component/BlogInnerHeroBanner/BlogInnerHeroBanner'
import BlogContent from '../component/BlogContent/BlogContent'
import Footer from "../component/Footer/Footer"
import BlogInnerLatest from "../component/BlogInnerLatest/BlogInnerLatest"
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import BlogContainer from '../component/BlogContainer/BlogContainer'


const newsData=[ 
  {
    blog_creative: "/images/creatives/blog-thumb-1.jpg",
    main_heading: "28 splendid big kitchen ideas you can follow to create",
    date:"",
    slug_url: "inner",
    catagory:"RESOURCES",
  },
  {
    blog_creative: "/images/creatives/blog-thumb-4.jpg",
    main_heading: "28 splendid big kitchen ideas you can follow to create",
    date:"",
    slug_url: "inner",
    catagory:"RESOURCES",
}
]

function BlogInner() {
  const [blogsData, setBlogsData] = useState();
  const [recentBlogs, setRecentBlogs] = useState([]);

  const [loading, setLoading] = useState(false);

  const id  = useParams().id;

  let blogAPICalledId = false;
  let allBlogsCalled = false;

  const getAllBlogs = async () => {
    if (allBlogsCalled) return;
    allBlogsCalled = true;

    setLoading(true);
    
    axios
      .get(`/blogs`, {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_recent_blogs = [];
          
          
          let blog = res.data.data.find((item) => item.slug_url == id);
          console.log(blog, id);
          setBlogsData(blog);

          res.data.data.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 2));
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err");
        setBlogsData(null)
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllBlogs();
  }, [id]);



  return (
    <>
    {loading  ? (
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner
              style={{ color: "black", width: "120px", height: "120px" }}
            />
          </div>
        ) :
        (
          <>
            {blogsData ?

              <HeroBanner 
              subHeading=""
              upperBlogPara={{
                date:blogsData.published_date,
              }}
              Heading={blogsData.title}
              bgImg="/images/creatives/blog-inner-hero-bg.jpg"
              btn={false}
              sideLinks={false}
              />
              :
              <HeroBanner 
              subHeading=""
              Heading=""
              bgImg="/images/creatives/blog-inner-hero-bg.jpg"
              btn={false}
              sideLinks={false}
              />
            }
            
          
            {blogsData && 
              <>
                <Helmet>
                  {blogsData.metaTitle && <title>{blogsData.metaTitle}</title>}
                  {blogsData.metaDescription && <meta name='description' content={blogsData.metaDescription} />}
                  {blogsData.canonicalLink && <link rel="canonical" href={blogsData.canonicalLink}></link>}
                </Helmet>
                

                {/* <img src="/images/creatives/blog-innner.jpg" className='img-fluid' alt='icon'/> */}

                
                <BlogContent
                  blogsData={blogsData.blogs_content}
                  text={blogsData.brief_paragraph}
                />

        <BlogContainer
          heading="Stay Updated on Our Latest News"
          content="Welcome to our blog section, where we explore trends in the jewellery industry, and provide custom design tips. Whether you're looking for expert advice or inspiration for your next piece, we're here to guide you every step of the way."
          btn={true}
          btnText="VIEW MORE"
          btnRef="/blog"
          
        />
       
            </>
           }
            
            </>
          )} 

        <Footer newsletterSection={false}/>
    </>
  )
}

export default BlogInner