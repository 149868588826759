import React from 'react'
import "./ServiceCheckList.css"

function ServiceCheckList({
    heading,
    subHeading,
    content,
    listArr=null
}) {
  return (
    <div className='ServiceCheckList-container standard-padding-space'>
        <div className="container my-5 py-4">
        <div className="row align-items-center">

      <div className="col-lg-5">
        <div className="blog-upper-content mb-5">
          <div className="containe">
              <div className="blog-text-content text-lg-start text-center">
                <p className="sub-heading mb-1 justify-content-center d-flex aling-items-center gap-lg-4 gap-3">{subHeading}</p>
                <h3 className="body-heading mb-2">{heading}</h3>   
                {content && <p className="body-paragraph my-3 pe-lg-4 pe-0 mt-4">{content}</p>}
                
              </div>
          </div>
        </div>
      </div>
      <div className="col-lg-7 d-flex justify-content-lg-center justify-content-start ps-5 ps-lg-0">
        {
            listArr &&
          
                <div className='checkList-container '>
                  {
                    listArr.map((ele,ind)=>{
                      return(
                          <div className='d-flex align-items-center  gap-3 mb-2'>
                            <img src='/images/icons/content-check-mark.svg' alt='check icon'/>
                            <p className='body-paragraph mb-1 text-start'>{ele}</p>
                          </div>
                      )
                    })
                  }
                </div>
        }
       
        </div>
        </div>
        
        </div>
    </div>
  )
}

export default ServiceCheckList