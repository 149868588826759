import "./OfferCardsContainer.css"
import React,{useState} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay  } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import TestimonialCard from "./OfferCardsContainerCard"

const offerData = [
  {
    thumbnail:"/images/creatives/offer-card-1.jpg",
    title:"LOOSE DIAMOND",
    desc:"1.27ct | brilliant round | lab grown diamond ",
    spec:"E colour | VS1 | Excellent Cut",
    ourPrice:"Our Price $ 1,350",
    rPrice:"Market Price $ 1,659",
    isSold:false,
  },
 
  // {
  //   thumbnail:"/images/creatives/offer-card-2.jpg",
  //   title:"LOOSE DIAMOND",
  //   desc:"2.82ct | brilliant round | lab grown diamond",
  //   spec:"E colour | VS1 | Excellent Cut",
  //   ourPrice:"Our Price $ 4,250",
  //   rPrice:"Market Price $ 7,120",
  //   isSold:false,
  // },
 
  {
    thumbnail:"/images/creatives/offer-card-3.jpg",
    title:"LOOSE DIAMOND",
    desc:"3.08ct | princess cut | lab grown diamond",
    spec:"E colour | VS1 | Excellent Cut",
    ourPrice:"Our Price $ 6,350",
    rPrice:"Market Price $ 9,160",
    isSold:false,
  },
  {
    thumbnail:"/images/wholesaleoffers/2.2ct_round_diamond.mp4",
    title:"LOOSE DIAMOND",
    desc:"2.20ct | Round | Lab-Grown diamond",
    spec:"D colour | VS1 | Excellent Cut",
    ourPrice:"Our Price $ 3,150",
    rPrice:"Market Price $ 4,563",
    isVideo:true,
    isSold:false,
  },
  {
    thumbnail:"/images/wholesaleoffers/2ct_oval_diamond.mp4",
    title:"LOOSE DIAMOND",
    desc:"2.00ct | Oval | Lab-Grown diamond",
    spec:"E colour | VS1 | Excellent Cut",
    ourPrice:"Our Price $ 2,975",
    rPrice:"Market Price $ 4,249",
    isVideo:true,
    isSold:false,
  },
 
] 

function OfferCardsContainer() {



  return (
    <div className="OfferCardsContainer-container py-5 mb-5">
      <div className="container w-100 container-fluid py-4">
    <div className=" d-flex align-items-center w-100">
      <div className="col-12 text-center px-0">
        <h3 className="body-heading mt-4">Current Offers</h3>
        <p className="body-paragraph mb-2 shrinked-para" >Occasionally, we have access to exclusive discounts and wholesale offers on loose diamonds</p>


        <div className="card-list" style={{position:"relative"}}>

        {/* <Swiper
        spaceBetween={30}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        loop={true}
        autoplay={
          {
            delay:2500,
            disableOnInteraction:false
          }
        }
        modules={[Autoplay]}
        pagination={{ clickable: true }}
        observer ={true}
        observeParents={true}
        parallax={true}
        breakpoints={{
            280: {
              slidesPerView: 1,
            },
            992: {
              slidesPerView: 3,
            }
          }}
        className="testimonials-list py-5 mt-2"
        id="testimonial-cards-slider"
            >
              {
                offerData.map((cardData,ind)=>{
                  return(<>
                    <SwiperSlide key={ind} className="pt-5 px-3 px-sm-0">
                      <TestimonialCard 
                      thumbnail={cardData.thumbnail} 
                      title={cardData.title} 
                      desc={cardData.desc}
                      spec={cardData.spec}
                      ourPrice={cardData.ourPrice}
                      rPrice={cardData.rPrice}
                      isSold={cardData.isSold}
                      
                      />
                    </SwiperSlide>
                    
                     </>)
                })
              }
        </Swiper> */}

        <div className="row">
        {
                offerData.map((cardData,ind)=>{
                  return(<>
                    <div key={ind} className="pt-5 px-3 px-sm-0 col-lg-4">
                      <TestimonialCard 
                      thumbnail={cardData.thumbnail} 
                      title={cardData.title} 
                      desc={cardData.desc}
                      spec={cardData.spec}
                      ourPrice={cardData.ourPrice}
                      rPrice={cardData.rPrice}
                      isSold={cardData.isSold}    
                      isVideo={cardData.isVideo}
                      />
                    </div>
                    
                     </>)
                })
              }

        </div>
        
        </div>
        
      </div>
      </div>
  </div>
</div>
  )
}

export default OfferCardsContainer