import React from 'react'
import DropDownBox from "./DropDownBox"
import "./FaqSection.css"

function FaqSection({  
    heading="",
    para="",
    subHeading="",
    showImg=false,
    faqData=[]
}) 
{
    

  return (
    <div className='faq-container standard-padding-space mt-3 mt-lg-5'>
        <div className='container my-0 my-md-4'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='text-content w-100 text-center text-lg-start'>
                        <img className='img-fluid mb-3' src='/images/icons/faq-section-icon.png' alt='person '/>
                        <h6 className='sub-heading my-4' >{subHeading}</h6>
                        <h3 className='body-heading mb-4'>{heading}</h3>
                        <p className='body-paragraph'>{para}</p>
                        
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='dropdown-container row mt-4'>
                        {
                            faqData.map((ele)=>{
                                return(
                                    <div className='col-12'>
                                        <DropDownBox title={ele.title} content={ele.content} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        
        
       
    </div>
    
</div>
  )
}

export default FaqSection