import React, { useState } from 'react'
import "./SaleRegisterForm.css"
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';

function SaleRegisterForm() {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
  
    const formSubmitHandler = (e) => {
      e.preventDefault();
      setLoading(true);
      axios
        .post("/send-newsletter", {
          email: email,
          formType: "Email Registration for Wholesale Offers",
        })
        .then((response) => {
          if (response.data.msg === "success") {
            setError(false);
            setSuccess("Email Registered Successfully!");
            setLoading(false);
            setEmail("");
          } else if (response.data.msg === "fail") {
            setSuccess(false);
            setError("Email failed to send, try again by reloading the page.");
            setLoading(false);
            setEmail("");
          }
          })
        }
  return (
         <div className="SaleRegisterForm-container standard-padding-space ">
            <div className="container py-5 px-4">
                <div className="form-container row gy-5 py-5 my-4">
                    <div className="col-12 mt-0">
                        <div 
                            className="text-container text-center d-flex flex-column align-items-center gap-3"

                        >
                            <h3 className="body-heading text-center main-heading" style={{textTransform:"uppercase",color:"#232323"}}>
                            REGISTER YOUR EMAIL HERE
                            </h3>
                           <p className="body-paragraph" style={{color:"#818181"}}>to get priority access to these special deals when they become available!</p>
                        </div>
                    </div>
                    <div className="col-lg-8 d-flex align-items-center justify-content-center mx-auto">
                        <form className='inp-container d-flex align-items-center justify-content-between' onSubmit={formSubmitHandler}>
                            <input 
                                type='text' 
                                placeholder='Enter your Email...'  
                                value={email}
                                maxLength="36"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button className='inp-btn d-flex align-items-center gap-2 '>
                                <span>SUBSCRIBE</span>
                                <img src="/images/icons/btn-bell-w.svg" className="bell-icon mb-0" alt="icon" loading='lazy'/>
                                {loading ? (
                                    <Spinner
                                    animation="border"
                                    role="status"
                                    style={{ color: "white", width: "15px", height: "15px", marginLeft: "10px" }}
                                    />
                                ) : (
                                    success ? (
                                    <div >
                                        {setLoading(false)}
                                        {alert(success)}
                                        {setSuccess(false)}
                                    </div>
                                    ) : (
                                    error && (
                                        <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                        </div>
                                    )
                                    )
                                )}
                            </button>
                        </form>
                    </div>
                
                
                </div>
            </div>
            </div>
  )
}

export default SaleRegisterForm