import React,{useState} from 'react'

function DropDownBox(props) {
    const [dropOpen , setDropOpen] = useState(false)
  return (
    <div
      className={`dropdown-box container `}
      onClick={() => {
        setDropOpen((prev) => {
          return !prev;
        });
      }}
      
    >
      <div className="box-head d-flex align-items-center justify-content-between gap-3"
        style={dropOpen ? {backgroundColor:"#F5F3EF"}:{backgroundColor:"white"}}
      >
        <h5 className="title mb-0 font-Basker" >{props.title}</h5>
        <img src={dropOpen ? '/images/icons/dropdown-less.svg':'/images/icons/dropdown-add.svg'} className='eye-icon' alt='dropdown-icon'/>
      </div>
      <div className="box-content " style={dropOpen?{animation:"bottomBoxOpen .6s ease-in-out",display:"block"}:{display:"none"}}>
          {/* <p className='mb-0'>{props.content}</p> */}
          <p className='mb-0' dangerouslySetInnerHTML={{__html:props.content}}></p>
      </div>
    </div>
  );
}

export default DropDownBox