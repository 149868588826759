import React from 'react'
import "./BlogContent.css"

function BlogContent({ text, blogsData }) {
 
  return (
    <div className='BlogContent-container pb-5'>
        <div className='container my-3'>
          {/* <div className='row gy-4 flex-column-reverse flex-lg-row'>
            <div className='col-xl-7 col-lg-6'>
              <div className='blog-content'>
                <div className={`body-paragraph my-3 color-dark`} dangerouslySetInnerHTML={{ __html: text }}></div>
                  {blogsData.map((item) => (
                    <>
                      <h2 className="body-heading color-dark" style={{textTransform:"capatalize"}}>{item.heading}</h2>
                      <div className={`body-paragraph my-3 color-dark`} dangerouslySetInnerHTML={{ __html: item.paragraph }}></div>
                    </>
                  ))}
                
                </div>
            </div>
            <div className='col-xl-5 col-lg-6'>
              <div className='card-section text-center '>
                <img className='card-logo ' src='/images/icons/card-logo-img.png' alt='icon'/>

                <h3 className='body-heading title color-dark mt-4'>MIRNA ELIE SARJI</h3>
                <h6 className='sub-heading my-3' style={{color:"#717171"}}>DESIGNER</h6>
                

                <div className='d-flex gap-3 justify-content-center align-items-center mt-5'>
                  <a href="https://www.facebook.com/mesdecors.homestaging" target="blank" rel="noopener noreferrer">
                    <img src='/images/icons/blog-card-icon-1.svg' alt='social icon'/>
                  </a>

                  <a href="https://www.instagram.com/mesdecors.homestaging/ " target="blank" rel="noopener noreferrer">
                    <img src='/images/icons/blog-card-icon-2.svg' alt='social icon'/>
                  </a>

                  <a href="https://www.linkedin.com/company/mes-d-cors-inc/ " target="blank" rel="noopener noreferrer">
                    <img src='/images/icons/blog-card-icon-3.svg' alt='social icon'/>
                  </a>

                  <a href="https://www.tiktok.com/@mes.decors " target="blank" rel="noopener noreferrer">
                    <img src='/images/icons/blog-card-icon-4.svg' alt='social icon'/>
                  </a>
                </div>
              </div>
            </div>
          </div> */}

              <div className='blog-conten'>
                <div className={`body-paragraph my-3 color-dark`} dangerouslySetInnerHTML={{ __html: text }}></div>
                  {blogsData.map((item) => (
                    <>
                      <h2 className="body-heading color-dark" style={{textTransform:"capatalize"}}>{item.heading}</h2>
                      <div className={`body-paragraph my-3 color-dark`} dangerouslySetInnerHTML={{ __html: item.paragraph }}></div>
                    </>
                  ))}
                
                </div>
        </div>

    </div>
  )
}

export default BlogContent