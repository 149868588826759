import React,{useState} from "react";
import "./Contactform.css";
import axios from "axios"
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Contactform = ({
  sideImg="/images/creatives/contact-form-img.png",
  heading=""
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-quote", {
        email: email,
        name:name,
        message:message,
        phone:phone,
        formType: "Contact Form",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Contact Form sended Successfully!");
          setLoading(false);
          setEmail("");
          navigate("/thank-you");
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Failed to send, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };

  const handlePhoneChange = (e) => {
   const numericValue = e.target.value.replace(/\D/g, ''); 
    const maxLength = 20; 
    if (numericValue.length <= maxLength) {
      setPhone(numericValue);
    }
  };


  return (
    <div
      className="contact_section mt-4 pt-5"
    >
      <div className="right-gradient-botto">
      <div className="left-gradient-uppe pb-5">
      <div className="container ">
        <div className="row gy-4 gx-lg-5 d-flex align-items-center">
          <div className="col-md-6 pb-5 m-0" >
            {
              heading ?
              <h3 className="hero-section-heading text-lg-start text-center" style={{color:"black"}}>{heading}</h3>
              :
              <img src={sideImg} className="img-fluid" alt="jewellery icon " loading='lazy'/>
            }
          </div>
          <div className="col-md-6 py-5 ">
            
            <div className="row gy-4">

              <div className="col-12">
                <form className="contact_form_form_container" onSubmit={formSubmitHandler}> 
                  <div className="row gy-4">
                    <div className="col-md-6 col-12">
                      <input
                        required
                        type="text"
                        value={name}
                        maxLength="40"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Your Full Name"
                      />
                    </div>
                    <div className="col-md-6 col-12">
                      <input
                        required
                        type="email"
                        value={email}
                        maxLength="36"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Your Email Address"
                      />
                    </div>
                    <div className="col-md-12 col-12">
                      <input
                        required
                        type="tel"
                        value={phone}
                        maxLength="22"
                        onChange={handlePhoneChange}
                        placeholder="Phone Number"
                      />
                    </div>
                    <div className="col-md-12 col-12">
                      <textarea
                       required
                       type="text"
                       value={message}
                       maxLength="70"
                       onChange={(e) => setMessage(e.target.value)}
                        placeholder="Write Your Message"
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 button_col d-flex align-items-center justify-content-start">
                      <button type="submit" className="genral-btn-light mt-4">Submit
                      </button>
                      {loading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            style={{ color: "black",marginLeft:"10px" }}
                          />
                    ) : (
                      success ? (
                        <div>
                          {setLoading(false)}
                          {alert(success)}
                          {setSuccess(false)}
                        </div>
                      ) : (
                        error && (
                          <div>
                            {setLoading(false)}
                            {alert(error)}
                            {setError(false)}
                          </div>
                        )
                      )
                    )}
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default Contactform;
