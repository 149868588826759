import {useNavigate} from "react-router-dom"
 
function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + '...';
  }
}

function CardBox(props) {
  const navigate = useNavigate()
  
  const breifTitle = truncateText(props.title,50)
  // const breifPara = truncateText(props.content,100)
  return (
    <div className="card-box"  >
      <div className="w-100">
        <img src={props.cardImg} alt="someImage" />
        <div className="card-text-conten">
         <p className="body-paragraph mb-1" style={{color:"#818181"}}>{props.date ?? ""} . Lee David</p>
         <div className="line-seprator"></div>
          <h5 className="title my-3 text-start" style={{color:"#232323"}}>{breifTitle}</h5>
          {/* <div className="body-paragraph text-center mb-4" dangerouslySetInnerHTML={{__html : breifPara}}></div> */}

          <a
            onClick={()=>{props.redirectUserToBlog(props.anchor)}}
            style={{color:"black",textDecoration:"underline",fontWeight:"bold"}}
          >Read More</a>
        </div>
      </div>
       
    </div>
  )
}

export default CardBox