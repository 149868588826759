import React from 'react'
import "./DropDownContent.css"
import DropDownBox from "./DropDownBox"

const dropdownData = [
    {
        title:"Public Cloud Integration",
        content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
    },
    {
        title:"24/7 Monitoring",
        content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
    },
    {
        title:"Data Backup Solutions",
        content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
    },
    {
        title:"IT Consultation",
        content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
    },
]



function DropDownContent({
    shiftRow=false,
    img,
    mobileTextCenter=true
  }) {
    return (
      <div className='drop-down-content-section standard-padding-space'>
        <div className='container'>
          <div className={`row d-flex ${shiftRow && "flex-row-reverse"} align-items-center gy-2`} >
            <div className={`col-lg-6 p-2 px-3 px-lg-2 d-flex align-items-center justify-content-center ${shiftRow ? "justify-content-lg-end" : "justify-content-lg-start"}`}>
              <img src={img} className='img-fluid' alt='icon'/>
            </div>
            <div className='col-lg-6'>
              <div className={`text-content ${mobileTextCenter && "text-center "} `}>
                
              {
                    dropdownData.map((ele)=>{
                        return(
                            <div className='col-12'>
                                <DropDownBox title={ele.title} content={ele.content} />
                            </div>
                        )
                    })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

export default DropDownContent