import { useState } from "react";
import "./ImgModal.css";

export default function Modal(props) {

  return (
    <div className="img-modal-container" onClick={props.closeModal} style={props.status ? {display : "block"} : {display:"none"}}>
      <div className="overlay" ></div>
      <div className="modal-main d-flex justify-content-center align-items-center w-fit">
          
      {props.showImg.endsWith('.mp4') ? (
        <video src={props.showImg}  className="modal-video" autoplay controls muted/>
      ) : (
        <img src={props.showImg} alt="modal" className="modal-img" />
      )}
            {/* <img src={props.showImg} alt="modal " className=" modal-img"/> */}
        </div>
    </div>
    );
}